import React from 'react';
import { Link } from 'react-router-dom'; // Use Link for navigation between routes
import '../styles/NavBar.css';

const NavBar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-content">
        <Link to="/"><img src="/images/logo.png" className="navbar-logo" alt="logo" /></Link>
        <ul className="navbar-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/curriculums">Programs</Link></li>
          <li><Link to="/games">Games</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;

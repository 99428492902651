// HomeScreen.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/HomeScreen.css';

const HomeScreen = () => {
  const navigate = useNavigate();

  const handlePlayButtonClick = () => {
    console.log('Start clicked!');
    navigate('/curriculums');
  };

  useEffect(() => {
    // Force a resize event after component mounts
    window.dispatchEvent(new Event('resize'));
  }, []);

  return (
    <div className="home-container">
      <main className="main-content">
        <div className="content-wrapper">
          <h1 className="welcome-title">Welcome to Overture Games</h1>
          <h4 className="vibe-title">
            <span className="vibe-hover">Aura</span>
            <span className="vibe-word">Unlocked,</span>
            <span className="vibe-hover">Vibes</span>
            <span className="vibe-word">Unstoppable</span>
          </h4>
          <div className="text-image-container">
            <div className="text-section">
              <section className="info-section">
                <h2 className="section-title">What is Overture Games?</h2>
                <ol className="custom-numbered-list">
                  <li>
                    <span className="list-highlight">Engaging STEM & Music</span>
                    <p className="list-detail">Interactive curriculum designed for modern learning</p>
                  </li>
                  <li>
                    <span className="list-highlight">Expert-Built Platform</span>
                    <p className="list-detail">Created by Northwestern University experts</p>
                  </li>
                  <li>
                    <span className="list-highlight">Always Available</span>
                    <p className="list-detail">24/7 support when you need it</p>
                  </li>
                </ol>
              </section>
              <section className="info-section">
                <h2 className="section-title">Getting Started</h2>
                <div className="steps-container">
                  {['Click Start', 'Choose Lesson', 'Have Fun!'].map((step, index) => (
                    <div key={index} className="step-item">
                      <div className="step-number">{index + 1}</div>
                      <p>{step}</p>
                    </div>
                  ))}
                </div>
              </section>
            </div>
            <div className="image-section">
              <div className="songly-container">
                <img 
                  src={process.env.PUBLIC_URL + '/images/SonglySkydiving.png'}
                  alt="Songly skydiving with headphones" 
                  className="songly-image"
                  onError={(e) => {
                    console.error('Error loading image');
                    e.target.style.display = 'none';
                  }}
                />
              </div>
            </div>
          </div>
          <div className="start-link-container">
            <button className="start-link" onClick={handlePlayButtonClick}>
              🎵 Start Your Journey
              <span className="arrow">→</span>
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomeScreen;


import React from 'react';
import { Link } from 'react-router-dom';
import gamesConfig from '../components/GamesConfig';
import '../styles/GamesDisplayScreen.css';

const GamesDisplayScreen = () => {
  const games = Object.keys(gamesConfig).map((gameName) => ({
    name: gameName,
    ...gamesConfig[gameName],
  }));

  // Get the first 3 games for new releases
  const newReleases = games.slice(0, 3);
  const otherGames = games.slice(3);

  return (
    <div className="games-display-screen">
      <div className="title-section">
        <h1 className="section-title">Our Games</h1>
        <div className="songly-flag-container">
          <img 
            src="/images/SonglyFlag1.png" 
            alt="Songly with flag" 
            className="songly-flag"
          />
        </div>
      </div>
      <div className="games-content">
        <h2 className="section-subtitle">New Releases</h2>
        <div className="new-releases-grid">
          {newReleases.map((game) => (
            <div className="game-card new-release-card" key={game.name}>
              <div className="new-release-badge">New!</div>
              <Link to={`/game/${encodeURIComponent(game.name)}`} className="game-link">
                <div className="game-image-container">
                  <img src={game.image} alt={game.name} className="game-image" />
                </div>
                <div className="game-details">
                  <h2>{game.displayTitle}</h2>
                  <p>{game.description}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>

        <h2 className="section-subtitle">All Games</h2>
        <div className="games-grid">
          {otherGames.map((game) => (
            <div className="game-card" key={game.name}>
              <Link to={`/game/${encodeURIComponent(game.name)}`} className="game-link">
                <div className="game-image-container">
                  <img src={game.image} alt={game.name} className="game-image" />
                </div>
                <div className="game-details">
                  <h2>{game.displayTitle}</h2>
                  <p>{game.description}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GamesDisplayScreen;

import React, { useState} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomeScreen from './pages/HomeScreen';
import CurriculumsScreen from './pages/CurriculumsScreen';
import LessonsScreen from './pages/LessonsScreen';
import LessonPlanScreen from './pages/LessonPlanScreen';
import PresentScreen from './pages/PresentScreen';
import GameScreen from './pages/GameScreen';
import GamesDisplayScreen from './pages/GamesDisplayScreen';
import CanvaPresentationScreen from './pages/CanvaPresentationScreen';
import './App.css';
import NavBar from './components/NavBar';
import Demo from "./components/DialogModal/Demo";
import DraggableMidiKeyboard from './components/DraggableMidiKeyboard';
import KeyboardToggleButton from './components/KeyboardToggleButton';

// Create a wrapper component for the keyboard
const KeyboardWrapper = () => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const location = useLocation();

  // Only show keyboard toggle button on Music Kombat page
  const isMusicKombatPage = location.pathname.includes('MusicKombat');

  if (!isMusicKombatPage) return null;

  return (
    <>
      <KeyboardToggleButton toggleKeyboard={() => setIsKeyboardVisible(!isKeyboardVisible)} />
      <DraggableMidiKeyboard 
        isVisible={isKeyboardVisible} 
        toggleVisibility={() => setIsKeyboardVisible(!isKeyboardVisible)}
      />
    </>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/curriculums" element={<CurriculumsScreen />} />
          <Route path="/curriculum/:curriculumId/lessons" element={<LessonsScreen />} />
          <Route path="/curriculum/:curriculumId/lesson/:lessonId" element={<LessonPlanScreen />} />
          <Route path="/curriculum/:curriculumId/lesson/:lessonId/present/:sectionName" element={<PresentScreen />} />
          <Route path="/curriculum/:curriculumId/lesson/:lessonId/canva" element={<CanvaPresentationScreen />} />
          <Route path="/games" element={<GamesDisplayScreen />} />
          <Route path="/game/:gameName" element={<GameScreen />} />
          <Route
            path='lesson-2-demo'
            element={<Demo />}
          />
        </Routes>
        <KeyboardWrapper />
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import { useParams } from 'react-router-dom';
import WebGLGameComponent from '../components/WebGLGameComponent';
import gamesConfig from '../components/GamesConfig';
import '../styles/GameScreen.css';

const GameScreen = () => {
  const { gameName: encodedGameName } = useParams();
  const gameName = decodeURIComponent(encodedGameName);
  const gameConfig = gamesConfig[gameName];

  if (!gameConfig) {
    return (
      <div className="game-screen">
        <h1>Game Not Found</h1>
        <p>The game "{gameName}" does not exist.</p>
      </div>
    );
  }

  return (
    <div className="game-screen">
      <h1 className="game-title">{gameConfig.displayTitle}</h1>
      <div className="game-container">
        <WebGLGameComponent
          gameName={gameName}
          buildFolder={gameConfig.buildFolder}
          companyName={gameConfig.companyName}
          productVersion={gameConfig.productVersion}
        />
      </div>
    </div>
  );
};

export default GameScreen;

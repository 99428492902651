// LessonsScreen.js
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/LessonsScreen.css';
import curriculumsData from '../assets/curriculumsData.json';
import * as LucideIcons from 'lucide-react';

const LessonsScreen = () => {
  const navigate = useNavigate();
  const { curriculumId } = useParams();

  const curriculum = curriculumsData.curriculums.find(c => c.id === curriculumId);

  if (!curriculum) {
    return <div>Curriculum not found</div>;
  }

  const handleLessonClick = (lesson) => {
    navigate(`/curriculum/${curriculumId}/lesson/${lesson.id}/canva`);
  };

  const getLessonIcon = (iconName, color) => {
    const IconComponent = LucideIcons[iconName];
    return IconComponent ? <IconComponent color={color} /> : <LucideIcons.Music color={color} />;
  };

  return (
    <div className="lessons-screen">
      <div className="lessons-card">
        <div className="curriculum-image-wrapper">
          <img 
            src={curriculum.imageUrl} 
            alt={curriculum.title}
            className="curriculum-mascot"
          />
        </div>
        <div className="lessons-header">
          <h1 className="lessons-title">{curriculum.title}</h1>
          <p className="lessons-subtitle">Explore our interactive lessons!</p>
        </div>

        <div className="lessons-list">
          {curriculum.lessons.map((lesson) => (
            <div
              key={lesson.id}
              className={`lesson-item lesson-${lesson.type}`}
              onClick={() => handleLessonClick(lesson)}
              style={{ '--lesson-color': lesson.color }}
            >
              <div className="lesson-info">
                <div className="lesson-icon">
                  {getLessonIcon(lesson.icon, lesson.color)}
                </div>
                <h3 className="lesson-title" style={{ color: lesson.color }}>
                  {lesson.title}
                </h3>
              </div>
              <button className="lesson-button" style={{ backgroundColor: lesson.color }}>
                Start Lesson
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LessonsScreen;

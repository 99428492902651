import React from 'react';
import '../styles/KeyboardToggleButton.css';

const KeyboardToggleButton = ({ toggleKeyboard }) => {
  return (
    <button className="keyboard-toggle" onClick={toggleKeyboard}>
      🎹
    </button>
  );
};

export default KeyboardToggleButton; 
// Place this file in your config directory or suitable location
const gamesConfig = {
  'RocketRace': {
    buildFolder: 'WebGLBuilds/RocketRace/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/rocketrace.png",
    description: "Compete as a team to learn INSTRUMENTS and HARMONY.",
    displayTitle: "Rocket Race"
  },
  'BeatDecoders3.0': {
    buildFolder: 'WebGLBuilds/BeatDecoders3.0/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/BeatDecodersV3.png",
    description: "Build a beat and learn RHYTHM.",
    displayTitle: "Beat Decoders V3"
  },
  'Melopedes': {
    buildFolder: 'WebGLBuilds/Melopedes/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/MelopedeCaves.png",
    description: "Make MELODY with cute bugs.",
    displayTitle: "Melopede Caves"
  },
  'MusicKombat': {
    buildFolder: 'WebGLBuilds/MusicKombat/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/music_kombat.png",
    description: "Battle with music in this RHYTHM fighting game!",
    displayTitle: "Music Kombat"
  },
  'YeetTheScoop': {
    buildFolder: 'WebGLBuilds/YeetTheScoop/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/YeetTheScoop.png",
    description: "Deliver Delicious HARMONY.",
    displayTitle: "Yeet the Scoop"
  },
  'RhythmRally': {
    buildFolder: 'WebGLBuilds/RhythmRally/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/rhythm_rally.png",
    description: "Compete and improve your RHYTHM memory.",
    displayTitle: "Rhythm Rally"
  },
  'ReactAttackTeacher': {
    buildFolder: 'WebGLBuilds/ReactAttackTeacher/Build',
    companyName: 'OvertureGames',
    productVersion: '1.0',
    image: "/images/ReactAttackTeacher.png",
    description: "Host a memey FORM competition!",
    displayTitle: "React Attack: Teachers"
  },
  'ReactAttackStudent': {
    buildFolder: 'WebGLBuilds/ReactAttackTeacher/Build',
    companyName: 'OvertureGames',
    productVersion: '1.0',
    image: "/images/ReactAttackStudent.png",
    description: "Describe music FORM with memes!",
    displayTitle: "React Attack: Students"
  },
  'HarmonyGuesser': {
    buildFolder: 'WebGLBuilds/HarmonyGuesser/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/harmony_guesser.png",
    description: "Learn the difference between major and minor chords.",
    displayTitle: "Harmony Guesser: Major or Minor?"
  },
  'HarmonyVotersTeacher': {
    buildFolder: 'WebGLBuilds/HarmonyVotersTeacher/Build',
    companyName: 'OvertureGames',
    productVersion: '1.0',
    image: "/images/HarmonyVotersTeacher.png",
    description: "Host voting-based composing for HARMONY & INSTRUMENTS!",
    displayTitle: "Music Voters: Teacher"
  },
  'HarmonyVotersStudents': {
    buildFolder: 'WebGLBuilds/HarmonyVotersStudents/Build',
    companyName: 'OvertureGames',
    productVersion: '1.0',
    image: "/images/HarmonyVotersStudent.png",
    description: "Vote for best HARMONY & INSTRUMENTS and create your track!",
    displayTitle: "Music Voters: Students"
  },
  "Songly": {
    buildFolder: "WebGLBuilds/Songly/Build",
    companyName: "Overture Games",
    productVersion: "1.1",
    image: "/images/Songly.png",
    description: "Craft a structured MELODY!",
    displayTitle: "Songly: Melodic Form"
  },
  "InstrumentGuesser1": {
    buildFolder: "WebGLBuilds/InstrumentGuesser1/Build",
    companyName: "Overture Games",
    productVersion: "1.0",
    image: "/images/instrument_guesser.png",
    description: "Work together to guess INSTRUMENTS by listening.",
    displayTitle: "Guess the Instrument!"
  },
  'BeatDecoders2.0': {
    buildFolder: 'WebGLBuilds/BeatDecoders2.0/Build',
    companyName: 'MikeBraverman',
    productVersion: '1.0',
    image: "/images/BeatDecodersV2.png",
    description: "Learn to make a beat | Discover the 1st element: RHYTHM.",
    displayTitle: "Beat Decoders V2: In Development"
  },
  'YeetTheScoop2D': {
    buildFolder: 'WebGLBuilds/YeetTheScoop2D/Build',
    companyName: 'Overture Games',
    productVersion: '1.0',
    image: "/images/YeetTheScoop2D.png",
    description: "Deliver Delicious HARMONY.",
    displayTitle: "Yeet the Scoop 2D"
  },
  "GravityGame": {
    buildFolder: "WebGLBuilds/GravityGame/Build",
    companyName: "Overture Games",
    productVersion: "1.1",
    image: "/images/gravity_game.png",
    description: "A fun brain break!",
    displayTitle: "Gravity Game"
  },
  'BeatDecoders': {
    buildFolder: 'WebGLBuilds/BeatDecoders/Build',
    companyName: 'MikeBraverman',
    productVersion: '1.0',
    image: "/images/RhythmDecoders.png",
    description: "Learn to make a beat | Discover the 1st element: RHYTHM.",
    displayTitle: "Beat Decoders V1"
  }
};
  
export default gamesConfig;
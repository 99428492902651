import React, { useEffect } from 'react';

const WebGLGameComponent = ({ gameName, buildFolder, companyName, productVersion }) => {
  useEffect(() => {
    // Element references for Unity setup
    const container = document.getElementById('unity-container');
    const canvas = document.getElementById('unity-canvas');
    const loadingBar = document.getElementById('unity-loading-bar');
    const progressBarFull = document.getElementById('unity-progress-bar-full');
    const fullscreenButton = document.getElementById('unity-fullscreen-button');
    const warningBanner = document.getElementById('unity-warning');

    // Function to show messages on Unity warning banner
    function unityShowBanner(msg, type) {
      function updateBannerVisibility() {
        warningBanner.style.display = warningBanner.children.length ? 'block' : 'none';
      }
      const div = document.createElement('div');
      div.innerHTML = msg;
      warningBanner.appendChild(div);
      if (type === 'error') {
        div.style.backgroundColor = 'red';
        div.style.padding = '10px';
      } else if (type === 'warning') {
        div.style.backgroundColor = 'yellow';
        div.style.padding = '10px';
        setTimeout(() => {
          warningBanner.removeChild(div);
          updateBannerVisibility();
        }, 5000);
      }
      updateBannerVisibility();
    }

    // Prepare Unity build config
    const formattedGameName = gameName.replace(/\s+/g, '_');
    const buildUrl = `${process.env.PUBLIC_URL}/${buildFolder}`;
    const loaderUrl = `${buildUrl}/${formattedGameName}.loader.js`;

    const config = {
      dataUrl: `${buildUrl}/${formattedGameName}.data`,
      frameworkUrl: `${buildUrl}/${formattedGameName}.framework.js`,
      codeUrl: `${buildUrl}/${formattedGameName}.wasm`,
      streamingAssetsUrl: 'StreamingAssets',
      companyName: companyName || 'Default Company',
      productName: gameName,
      productVersion: productVersion || '1.0',
      showBanner: unityShowBanner,
    };

    // Device check: mobile adjustments
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content =
        'width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes';
      document.getElementsByTagName('head')[0].appendChild(meta);
      container.className = 'unity-mobile';
      canvas.className = 'unity-mobile';
      
      // Set fixed dimensions for mobile/tablet
      canvas.style.width = '1067px';  // 4:3 ratio with 800px height
      canvas.style.height = '800px';
      unityShowBanner('WebGL builds load slowly on mobile devices :)', 'warning');
    } else {
      // Desktop dimensions
      canvas.style.width = '1067px';  // 4:3 ratio with 800px height
      canvas.style.height = '800px';
    }

    loadingBar.style.display = 'block';

    // Load Unity and setup MIDI
    const unityLoaderScript = document.createElement('script');
    unityLoaderScript.src = loaderUrl;
    
    unityLoaderScript.onload = () => {
      window.createUnityInstance(canvas, config, (progress) => {
        progressBarFull.style.width = 100 * progress + '%';
      })
        .then((unityInstance) => {
          window.unityInstance = unityInstance;
          loadingBar.style.display = 'none';
          fullscreenButton.onclick = () => {
            unityInstance.SetFullscreen(1);
          };
          canvas.unityInstance = unityInstance;

          // Initialize MIDI after Unity is loaded
          if (navigator.requestMIDIAccess) {
            navigator.requestMIDIAccess()
              .then((midiAccess) => {
                console.log("MIDI access obtained successfully.");
                for (let input of midiAccess.inputs.values()) {
                  console.log("Found MIDI input:", input.name);
                  input.onmidimessage = (event) => {
                    const [status, note, velocity] = event.data;
                    if (status === 254 || note === undefined || velocity === undefined) {
                      console.warn(`Ignoring invalid MIDI message: ${event.data}`);
                      return;
                    }
                    console.log(`Received MIDI: status=${status}, note=${note}, velocity=${velocity}`);
                    if (window.unityInstance && typeof window.unityInstance.SendMessage === "function") {
                      window.unityInstance.SendMessage(
                        "MIDIListener",
                        "ReceiveMIDIMessage",
                        `${status},${note},${velocity}`
                      );
                    }
                  };
                }
              })
              .catch(() => {
                console.error("Could not access your MIDI devices.");
              });
          } else {
            console.log("Web MIDI API not supported in this browser.");
          }
        })
        .catch((message) => {
          alert(message);
        });
    };

    unityLoaderScript.onerror = () => {
      console.error('Failed to load Unity loader script.');
    };

    document.body.appendChild(unityLoaderScript);

    // Cleanup function
    return () => {
      if (canvas.unityInstance) {
        canvas.unityInstance.Quit().then(() => {
          console.log('Unity instance unloaded.');
        });
      }
      if (document.body.contains(unityLoaderScript)) {
        document.body.removeChild(unityLoaderScript);
      }
    };
  }, [gameName, buildFolder, companyName, productVersion]);

  return (
    <div id="unity-container">
      <canvas id="unity-canvas" width="960" height="600"></canvas>
      <div id="unity-loading-bar">
        <div id="unity-logo"></div>
        <div id="unity-progress-bar-empty">
          <div id="unity-progress-bar-full"></div>
        </div>
      </div>
      <div id="unity-warning"></div>
      <div id="unity-footer">
        <div id="unity-webgl-logo"></div>
        <div id="unity-fullscreen-button"></div>
        <div id="unity-build-title">{gameName}</div>
      </div>
    </div>
  );
};

export default WebGLGameComponent;

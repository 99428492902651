import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import curriculumsData from '../assets/curriculumsData.json';
import '../styles/CanvaPresentationScreen.css';

function CanvaPresentationScreen() {
  const { curriculumId, lessonId } = useParams();
  const navigate = useNavigate();

  // Find the current curriculum and lesson
  const curriculum = curriculumsData.curriculums.find(c => c.id === curriculumId);
  const lesson = curriculum?.lessons.find(l => l.id === lessonId);

  if (!curriculum || !lesson) {
    return <div>Lesson not found</div>;
  }

  return (
    <div className="canva-screen">
      {lesson.googleDocUrl && (
        <iframe
          src={lesson.googleDocUrl}
          title={`${lesson.title} Document`}
          className="embed-iframe"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      )}

      <div className="button-container">
        <button 
          className="shared-button" 
          onClick={() => navigate(`/curriculum/${curriculumId}/lessons`)}
        >
          <ArrowLeft size={24} />
          <span>Back to Lessons</span>
        </button>
        {lesson.canvaUrl ? (
          <button 
            className="shared-button" 
            onClick={() => window.open(lesson.canvaUrl, '_blank')}
          >
            <span>Open Presentation</span>
            <ArrowRight size={24} />
          </button>
        ) : (
          <span className="no-presentation-text">
            No presentation slides built yet for this lesson
          </span>
        )}
      </div>
    </div>
  );
}

export default CanvaPresentationScreen;

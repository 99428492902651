import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/CurriculumsScreen.css';
import curriculumsData from '../assets/curriculumsData.json';

const CurriculumsScreen = () => {
  const navigate = useNavigate();

  // Separate curriculums into categories
  const musicPrograms = curriculumsData.curriculums.slice(0, 3);
  const stemPrograms = curriculumsData.curriculums.slice(3);

  return (
    <div className="curriculums-screen">
      <div className="curriculums-content">
        <h1 className="main-title">Our STEAM Programs</h1>
        
        {/* Music Programs Section */}
        <section className="program-section music-section">
          <h2 className="section-title">
            <span className="gradient-text">ste-ARTS-m</span>
          </h2>
          <div className="section-subtitle">Music</div>
          <div className="curriculums-grid">
            {musicPrograms.map((curriculum) => (
              <div 
                key={curriculum.id} 
                className="curriculum-card"
                onClick={() => navigate(`/curriculum/${curriculum.id}/lessons`)}
              >
                <div className="curriculum-image">
                  <img src={curriculum.imageUrl} alt={curriculum.title} />
                </div>
                <div className="curriculum-details">
                  <h3>{curriculum.title}</h3>
                  <p>{curriculum.description}</p>
                  <div className="lesson-count">
                    {curriculum.lessons.length} Lessons
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* STEM Programs Section */}
        <section className="program-section stem-section">
          <h2 className="section-title">
            <span className="gradient-text">STEM</span>
          </h2>
          <div className="section-subtitle">Technology & Innovation</div>
          <div className="curriculums-grid">
            {stemPrograms.map((curriculum) => (
              <div 
                key={curriculum.id} 
                className="curriculum-card"
                onClick={() => navigate(`/curriculum/${curriculum.id}/lessons`)}
              >
                <div className="curriculum-image">
                  <img src={curriculum.imageUrl} alt={curriculum.title} />
                </div>
                <div className="curriculum-details">
                  <h3>{curriculum.title}</h3>
                  <p>{curriculum.description}</p>
                  <div className="lesson-count">
                    {curriculum.lessons.length} Lessons
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default CurriculumsScreen; 
import React, {
  useState,
  useRef,
  useCallback,
  useEffect
} from "react";
import { Rnd } from "react-rnd";
import "../styles/DraggableMidiKeyboard.css";

const DraggableMidiKeyboard = ({ isVisible, toggleVisibility }) => {
  const [startOctave, setStartOctave] = useState(3);
  const [audioContext, setAudioContext] = useState(null);
  const [oscillators, setOscillators] = useState({});
  const [currentNoteId, setCurrentNoteId] = useState(null);
  const [startNote, setStartNote] = useState("A");
  const [isMusicKombat, setIsMusicKombat] = useState(false);

  const [position, setPosition] = useState({
    x: 20,
    y: window.innerHeight - 220,
  });
  const dragStartPos = useRef({ x: 0, y: 0 });
  const isDraggingKeyboard = useRef(false);

  const keyboardRef = useRef(null);
  const isMobile = window.innerWidth <= 768;

  // ---------------------------------------
  // DETECT MUSIC KOMBAT
  // ---------------------------------------
  useEffect(() => {
    const checkForMusicKombat = () => {
      setIsMusicKombat(window.location.href.includes("MusicKombat"));
    };
    checkForMusicKombat();

    window.addEventListener("popstate", checkForMusicKombat);
    return () => window.removeEventListener("popstate", checkForMusicKombat);
  }, []);

  // ---------------------------------------
  // AUDIO CONTEXT
  // ---------------------------------------
  const handleAudioContext = useCallback(() => {
    if (!audioContext) {
      const ctx = new (window.AudioContext || window.webkitAudioContext)();
      setAudioContext(ctx);
    } else if (audioContext.state === "suspended") {
      audioContext.resume();
    }
  }, [audioContext]);

  useEffect(() => {
    if (isVisible) {
      handleAudioContext();
    } else {
      // If hidden, stop leftover notes
      Object.values(oscillators).forEach(({ oscillator }) => oscillator.stop());
      setOscillators({});
      setCurrentNoteId(null);
    }
  }, [isVisible, handleAudioContext, oscillators]);

  useEffect(() => {
    return () => {
      if (audioContext) audioContext.close();
    };
  }, [audioContext]);

  // ---------------------------------------
  // NOTE PLAY/STOP
  // ---------------------------------------
  const noteValues = {
    A: 0,
    "A#": 1,
    B: 2,
    C: 3,
    "C#": 4,
    D: 5,
    "D#": 6,
    E: 7,
    F: 8,
    "F#": 9,
    G: 10,
    "G#": 11,
  };

  const getMIDINumber = (note, octave) =>
    21 + noteValues[note] + octave * 12; // A0 = 21

  const noteToFrequency = (midiNumber) => {
    // A4=69, 440Hz
    return 440 * Math.pow(2, (midiNumber - 69) / 12);
  };

  const createOscillator = (frequency) => {
    const osc = audioContext.createOscillator();
    const gainNode = audioContext.createGain();
    osc.type = "sine";
    osc.frequency.setValueAtTime(frequency, audioContext.currentTime);
    osc.connect(gainNode);
    gainNode.connect(audioContext.destination);
    return { oscillator: osc, gainNode };
  };

  const playNote = (note, octave) => {
    if (!audioContext) return;
    const noteId = `${note}-${octave}`;
    if (oscillators[noteId]) return; // already playing

    const midiNumber = getMIDINumber(note, octave);
    const freq = noteToFrequency(midiNumber);
    const { oscillator, gainNode } = createOscillator(freq);
    oscillator.start();

    setOscillators((prev) => ({ ...prev, [noteId]: { oscillator, gainNode } }));
    setCurrentNoteId(noteId);

    // MIDI Note On
    if (window.unityInstance) {
      window.unityInstance.SendMessage(
        "MIDIListener",
        "ReceiveMIDIMessage",
        `144,${midiNumber},100`
      );
    }
  };

  const stopNote = (note, octave) => {
    const noteId = `${note}-${octave}`;
    if (!oscillators[noteId]) return;

    const { oscillator, gainNode } = oscillators[noteId];
    // Fade out
    gainNode.gain.setValueAtTime(gainNode.gain.value, audioContext.currentTime);
    gainNode.gain.exponentialRampToValueAtTime(
      0.001,
      audioContext.currentTime + 0.3
    );
    oscillator.stop(audioContext.currentTime + 0.3);

    const midiNumber = getMIDINumber(note, octave);
    if (window.unityInstance) {
      window.unityInstance.SendMessage(
        "MIDIListener",
        "ReceiveMIDIMessage",
        `128,${midiNumber},0`
      );
    }

    setOscillators((prev) => {
      const copy = { ...prev };
      delete copy[noteId];
      return copy;
    });
    if (currentNoteId === noteId) {
      setCurrentNoteId(null);
    }
  };

  // Clean up all audio
  const cleanupAudio = useCallback(() => {
    Object.values(oscillators).forEach(({ oscillator, gainNode }) => {
      gainNode.gain.setValueAtTime(gainNode.gain.value, audioContext.currentTime);
      gainNode.gain.exponentialRampToValueAtTime(
        0.001,
        audioContext.currentTime + 0.3
      );
      oscillator.stop(audioContext.currentTime + 0.3);
    });
    setOscillators({});
    setCurrentNoteId(null);
  }, [audioContext, oscillators]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) cleanupAudio();
    };
    const handleBlur = () => cleanupAudio();

    window.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("blur", handleBlur);
    };
  }, [cleanupAudio]);

  // ---------------------------------------
  // KEYS
  // ---------------------------------------
  const allNotes = ["A", "A#", "B", "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#"];

  const generateKeys = (startingNote) => {
    const startIndex = allNotes.indexOf(startNote);
    const reordered = [
      ...allNotes.slice(startIndex),
      ...allNotes.slice(0, startIndex),
    ];
    return reordered.map((note, i) => {
      const isNextOctave =
        i >= 12 - startIndex && allNotes.indexOf(note) >= allNotes.indexOf("A");
      return {
        note,
        type: note.includes("#") ? "black" : "white",
        octaveOffset: isNextOctave ? 1 : 0,
      };
    });
  };

  const [keys, setKeys] = useState(generateKeys(startNote));

  // ---------------------------------------
  // CHANGE START NOTE, OCTAVE
  // ---------------------------------------
  const changeStartNote = () => {
    handleAudioContext();
    const currentIdx = allNotes.indexOf(startNote);
    const nextIdx = (currentIdx + 1) % allNotes.length;
    const newStart = allNotes[nextIdx];
    setStartNote(newStart);
    setKeys(generateKeys(newStart));
  };

  const handleOctaveChange = (newOctave) => {
    handleAudioContext();
    setStartOctave(newOctave);
  };

  // ---------------------------------------
  // MUSIC KOMBAT MAPPINGS
  // ---------------------------------------
  const combatMappings = {
    E2: { label: "P1 crouch", player: 1 },
    G2: { label: "P1 left", player: 1 },
    A3: { label: "P1 jump", player: 1 },
    B3: { label: "P2 right", player: 1 },
    E3: { label: "P1 s punch", player: 1 },
    "F#3": { label: "P1 m Punch", player: 1 },
    G3: { label: "P1 h PUNCH", player: 1 },
    A4: { label: "P1 s kick", player: 1 },
    B4: { label: "P1 m Kick", player: 1 },
    C4: { label: "P1 L KICK", player: 1 },
    E4: { label: "P2 crouch", player: 2 },
    G4: { label: "P2 left", player: 2 },
    A5: { label: "P2 jump", player: 2 },
    B5: { label: "P2 right", player: 2 },
    E5: { label: "P2 s punch", player: 2 },
    "F#5": { label: "P2 m punch", player: 2 },
    G5: { label: "P2 h punch", player: 2 },
    A6: { label: "P2 s kick", player: 2 },
    B6: { label: "P2 m kick", player: 2 },
    C6: { label: "P2 L kick", player: 2 },
  };

  const renderKeyLabel = (note, octave) => {
    const noteId = `${note}${octave}`;
    if (isMusicKombat && combatMappings[noteId]) {
      const { label, player } = combatMappings[noteId];
      return (
        <div className="combat-label-container">
          <span
            className="combat-label"
            style={{
              color: player === 1 ? "#007bff" : "#dc3545",
              whiteSpace: "nowrap",
              fontSize: "12px",
              fontWeight: "bold",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              padding: "2px 4px",
              borderRadius: "2px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%) rotate(-90deg)",
              zIndex: 10,
            }}
          >
            {label}
          </span>
        </div>
      );
    }
    return <span className="note-label">{note}{octave}</span>;
  };

  // ---------------------------------------
  // KEYBOARD CONTENT
  // ---------------------------------------
  const KeyboardContent = () => (
    <>
      <div className="keyboard-header">
        {/* This top bar is the "drag handle" */}
        <div className="keyboard-controls">
          <button
            className="octave-button"
            onClick={() => handleOctaveChange(Math.max(0, startOctave - 1))}
          >
            -
          </button>
          <span className="octave-display">
            Octaves: {startOctave}-{startOctave + 2}
          </span>
          <button
            className="octave-button"
            onClick={() => handleOctaveChange(Math.min(6, startOctave + 1))}
          >
            +
          </button>
          <button className="start-note-button" onClick={changeStartNote}>
            Start: {startNote}
          </button>
          <button className="close-button" onClick={toggleVisibility}>
            ×
          </button>
        </div>
      </div>

      <div className="piano-keyboard" ref={keyboardRef}>
        {[startOctave, startOctave + 1].map((octave) => (
          <div key={octave} className="octave-container">
            {keys.map(({ note, type, octaveOffset }) => {
              const fullOctave = octave + octaveOffset;
              const noteId = `${note}-${fullOctave}`;

              return (
                <div
                  key={noteId}
                  className={`key ${type}`}
                  onMouseDown={() => {
                    handleAudioContext();
                    playNote(note, fullOctave);
                  }}
                  onMouseUp={() => stopNote(note, fullOctave)}
                  onMouseMove={(e) => {
                    if (e.buttons === 1 && currentNoteId !== noteId) {
                      // Slide across new key
                      // Stop old note
                      if (currentNoteId) {
                        const [oldNote, oldOct] = currentNoteId.split("-");
                        stopNote(oldNote, parseInt(oldOct, 10));
                      }
                      playNote(note, fullOctave);
                    }
                  }}
                  onTouchStart={(e) => {
                    e.preventDefault(); // Prevent default to avoid double-firing
                    handleAudioContext();
                    playNote(note, fullOctave);
                  }}
                  onTouchMove={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onTouchEnd={(e) => {
                    e.preventDefault();
                    stopNote(note, fullOctave);
                  }}
                  onTouchCancel={(e) => {
                    e.preventDefault();
                    stopNote(note, fullOctave);
                  }}
                >
                  {renderKeyLabel(note, fullOctave)}
                </div>
              );
            })}
          </div>
        ))}
        {/* Extra key for the next octave's root note */}
        <div
          className="key white"
          onMouseDown={() => {
            handleAudioContext();
            playNote(startNote, startOctave + 2);
          }}
          onMouseUp={() => stopNote(startNote, startOctave + 2)}
          onTouchStart={(e) => {
            e.preventDefault();
            handleAudioContext();
            playNote(startNote, startOctave + 2);
          }}
          onTouchEnd={(e) => {
            e.preventDefault();
            stopNote(startNote, startOctave + 2);
          }}
          onTouchCancel={(e) => {
            e.preventDefault();
            stopNote(startNote, startOctave + 2);
          }}
        >
          {renderKeyLabel(startNote, startOctave + 2)}
        </div>
      </div>
    </>
  );

  // ---------------------------------------
  // MOBILE DRAG HANDLERS
  // ---------------------------------------
  const handleContainerTouchStart = (e) => {
    // Only drag if user tapped the "keyboard-header"
    if (e.target.closest(".keyboard-header")) {
      isDraggingKeyboard.current = true;
      const touch = e.touches[0];
      dragStartPos.current = {
        x: touch.clientX - position.x,
        y: touch.clientY - position.y,
      };
    } else {
      isDraggingKeyboard.current = false;
    }
  };

  const handleContainerTouchMove = (e) => {
    if (!isDraggingKeyboard.current) return; // user is playing keys

    const touch = e.touches[0];
    let newX = touch.clientX - dragStartPos.current.x;
    let newY = touch.clientY - dragStartPos.current.y;
    const maxX = window.innerWidth - 320;
    const maxY = window.innerHeight - 220;
    newX = Math.max(0, Math.min(newX, maxX));
    newY = Math.max(0, Math.min(newY, maxY));
    setPosition({ x: newX, y: newY });
  };

  // ---------------------------------------
  // SHOW / HIDE
  // ---------------------------------------
  if (!isVisible) return null;

  return isMobile ? (
    <div
      className="draggable-keyboard mobile"
      style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
      onTouchStart={handleContainerTouchStart}
      onTouchMove={handleContainerTouchMove}
    >
      <KeyboardContent />
    </div>
  ) : (
    <Rnd
      className="draggable-keyboard"
      // Only drag if user grabs "keyboard-header"
      dragHandleClassName="keyboard-header"
      default={{
        x: 0,
        y: window.innerHeight - 200,
        width: 800,
        height: 150,
      }}
      minWidth={600}
      minHeight={120}
      bounds="window"
    >
      <KeyboardContent />
    </Rnd>
  );
};

export default DraggableMidiKeyboard;
